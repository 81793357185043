import i18n from 'i18next';

export const nameRules = {
  maxLength: {
    value: 70,
    message: i18n.t('forms.validations.nameLength'),
  },
};

export const lastNameRules = {
  maxLength: {
    value: 70,
    message: i18n.t('forms.validations.lastNameLength'),
  },
};

export const addressRules = {
  maxLength: {
    value: 100,
    message: i18n.t('forms.validations.addressLength'),
  },
};

export const countryRules = {
  maxLength: {
    value: 30,
    message: i18n.t('forms.validations.country'),
  },
};

export const emailRules = {
  pattern: {
    value: /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: i18n.t('forms.validations.emailAddress'),
  },
};

export const normalizeEmail = (email: string): string => {
  return email.trim().toLowerCase();
};
