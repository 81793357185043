export type ChartData = {
  date?: string;
  cost?: number;
  value?: number;
  totalValue?: number;
  purchasePrice?: string;
};

export const chartSize = {
  width: '100%',
  height: 250,
};

export const linearGradientStyle = {
  id: 'colorPv',
  x1: '0',
  y1: '0',
  x2: '0',
  y2: '1',
};

export const lineChartMargin = {
  top: 5,
  right: 10,
  left: 20,
  bottom: 5,
};

export const pieChartMargin = {
  top: 20,
  right: 10,
  left: 20,
  bottom: 5,
};

export const lineStyle = {
  yAxisId: 'left',
  stroke: '#8884d8',
  activeDot: { r: 8 },
};

export const lineType = 'monotone';

export const areaChartStyle = {
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
};

export const areaColor = 'rgba(217, 217, 217, 1)';

export const areaStrokeColor = '#82ca9d';
