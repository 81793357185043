import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Stack, Typography } from '@mui/material';
import style from './style';
import { ControlledInputPhone } from 'app/components/Inputs/ControlledInputPhone';
import { UseFormReturn } from 'react-hook-form';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import { addressRules, lastNameRules, nameRules } from 'utils/validation.utils';

interface UserInformationstripeFormProps {
  useFormInstance: UseFormReturn<any>;
}
export function UserInformationstripeForm({
  useFormInstance,
}: UserInformationstripeFormProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} sx={style.boxContainerStyles}>
      <Typography variant="h6" sx={style.heading}>
        {capitalize(t('stripeForm.placeholders.header'))}
      </Typography>
      <Stack direction="row" spacing={2}>
        <ControlledInput
          formInstance={useFormInstance}
          name="firstName"
          label={capitalize(t('signUp.placeholders.firstName'))}
          otherRules={nameRules}
        />
        <ControlledInput
          formInstance={useFormInstance}
          name="lastName"
          label={capitalize(t('signUp.placeholders.lastName'))}
          otherRules={lastNameRules}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ControlledInput
          formInstance={useFormInstance}
          name="country"
          label={capitalize(t('stripeForm.placeholders.country'))}
        />
        <ControlledInput
          formInstance={useFormInstance}
          name="state"
          label={capitalize(t('stripeForm.placeholders.state'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ControlledInput
          formInstance={useFormInstance}
          name="city"
          label={capitalize(t('stripeForm.placeholders.city'))}
        />
        <ControlledInput
          formInstance={useFormInstance}
          name="postalCode"
          label={capitalize(t('stripeForm.placeholders.pincode'))}
        />
      </Stack>
      <ControlledInputPhone
        useFormInstance={useFormInstance}
        otherRules={{
          required: false,
        }}
      />
      <Stack direction="row" spacing={2}>
        <ControlledInput
          formInstance={useFormInstance}
          name="line1"
          label={capitalize(t('stripeForm.placeholders.addressline1'))}
          otherRules={{
            required: false,
            ...addressRules,
          }}
        />
        <ControlledInput
          formInstance={useFormInstance}
          name="line2"
          label={capitalize(t('stripeForm.placeholders.addressline2'))}
          otherRules={{
            required: false,
            ...addressRules,
          }}
        />
      </Stack>
    </Stack>
  );
}
