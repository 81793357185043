import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  AvatarGroup,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { ExpandMore, Apartment } from '@mui/icons-material';
import { FolderAccordion } from '../folder-accordion';
import { ASSET_ROW_WIDTH } from 'app/pages/vault/utils/files.utils';
import {
  getGroupTypeIcon,
  getGroupType,
  getGroupTypeBackground,
} from 'app/pages/vault/utils/guards.utils';
import { Entity, VaultFile, Folder } from '../../models/vault.types';
import * as style from './style';

type Props = {
  entity: Entity;
  onPreviewFile: (file: VaultFile) => void;
  onAddNewFile: (folder: Folder) => void;
  onAddNewLink: () => void;
  onRename: (file: VaultFile) => void;
  onDelete: (file: VaultFile) => void;
  onMoveToFolder: (file: VaultFile) => void;
};

export const EntityAccordion = React.memo(
  ({
    entity,
    onPreviewFile,
    onAddNewFile,
    onAddNewLink,
    onRename,
    onDelete,
    onMoveToFolder,
  }: Props) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
      setExpanded(prevExpanded => !prevExpanded);
    };

    useEffect(() => {
      if (entity.files.isIncluded) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }, [entity.files.isIncluded]);

    return (
      <Box>
        <Accordion
          sx={style.accordion}
          expanded={expanded}
          onChange={handleExpansion}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={style.accordionSummary}
          >
            <Grid sx={style.accordionHeader}>
              <Grid display="flex" flexDirection="row" alignItems="center">
                <Avatar
                  sx={{
                    backgroundColor: getGroupTypeIcon(entity.group.groupType),
                  }}
                >
                  <Apartment />
                </Avatar>
                <Typography sx={{ marginLeft: 1 }} variant="body1">
                  {entity.group.groupName}
                </Typography>
              </Grid>
              <Grid>
                <AvatarGroup max={2}>
                  {entity.stakeholders?.map(stakeholder => (
                    <Avatar
                      key={stakeholder.targetUserId}
                      alt={`${stakeholder.targetUserMeta.firstName} ${stakeholder.targetUserMeta.lastName}`}
                      src="/broken-image.jpg"
                    />
                  ))}
                </AvatarGroup>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={style.accordionDetails}>
            {entity.files?.children?.map(
              item =>
                item.itemType === undefined && (
                  <FolderAccordion
                    key={item.name}
                    item={item}
                    depth={2}
                    onPreviewFile={onPreviewFile}
                    onAddNewFile={onAddNewFile}
                    onAddNewLink={onAddNewLink}
                    onRename={onRename}
                    onDelete={onDelete}
                    onMoveToFolder={onMoveToFolder}
                  />
                ),
            )}
            <Grid
              sx={{
                ...style.accordionHeaderRow,
                backgroundColor: getGroupTypeBackground(entity.group.groupType),
              }}
            >
              <Grid width="30%">
                <Typography variant="body1" fontWeight="bold">
                  {t('vault.labels.assetsLiabilities')}
                </Typography>
              </Grid>
              <Grid
                display="flex"
                flexDirection="row"
                width="15%"
                paddingLeft={1}
              >
                <Typography variant="body1" fontWeight="bold">
                  {t('vault.labels.type')}
                </Typography>
              </Grid>
              <Grid display="flex" flexDirection="row" width="15%">
                <Typography variant="body1" fontWeight="bold">
                  {t('vault.labels.owner')}
                </Typography>
              </Grid>
              <Grid
                display="flex"
                flexDirection="row"
                width="15%"
                paddingLeft={1}
              >
                <Typography variant="body1" fontWeight="bold">
                  {t('vault.labels.lastModified')}
                </Typography>
              </Grid>
              <Grid width="15%">
                <Typography variant="body1" fontWeight="bold" paddingLeft={1}>
                  {t('vault.labels.size')}
                </Typography>
              </Grid>
              <Grid width={-2} />
            </Grid>

            <Box className="files">
              {entity.files?.children?.map(
                item =>
                  item.itemType !== undefined && (
                    <FolderAccordion
                      key={item.name}
                      item={item}
                      depth={1}
                      onPreviewFile={onPreviewFile}
                      onAddNewFile={onAddNewFile}
                      onAddNewLink={onAddNewLink}
                      onRename={onRename}
                      onDelete={onDelete}
                      onMoveToFolder={onMoveToFolder}
                    />
                  ),
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  },
);
