export default {
  userHomeStyles: {
    padding: '16px 1em 0 0',
    marginTop: 2.5,
    marginBottom: 10,
  },
  userRightPanelStyles: {
    minWidth: '200px',
  },
};
