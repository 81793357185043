type ConfigOptions = {
  [key: string]: string;
};

const Config: ConfigOptions = {
  REACT_APP_CONTACT_US_URL: process.env.REACT_APP_CONTACT_US_URL || '',
  NODE_ENV: process.env.NODE_ENV || '',
  VERSION: process.env.REACT_APP_VERSION || '1.0.0',
  TALKJS_APP_ID: process.env.REACT_APP_TALKJS_APP_ID || '',
  REACT_APP_MSW_ENABLED: process.env.REACT_APP_MSW_ENABLED || '',
  OSS_API_URL: process.env.REACT_APP_UBE_BASE_URL || 'http://localhost:3000',
  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  REACT_APP_AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY || '',
  ML_API_URL: process.env.REACT_APP_ML_API_BASE_URL || 'http://localhost:5000',
  REACT_APP_STRIPE_PRICING_TABLE_ID:
    process.env.REACT_APP_STRIPE_PRICING_TABLE_ID || '',
  REACT_APP_STRIPE_PUBLISHABLE_KEY:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  REACT_APP_WEALTHICA_CLIENT_ID:
    process.env.REACT_APP_WEALTHICA_CLIENT_ID || '',
  REACT_APP_WEALTHICA_PROVIDERS:
    process.env.REACT_APP_WEALTHICA_PROVIDERS || 'core',
  REACT_APP_LOGO_API_TOKEN: process.env.REACT_APP_LOGO_API_TOKEN || '',
};

export default Config;
