import { CurrencyStateProps } from 'providers/CurrencyProvider/types';
import { GroupData, Stakeholder } from '../GroupSlice/types';
import {
  AccountingBalanceSheet,
  CashFlowStatement,
  ProfitAndLossReport,
} from '@codat/lending/dist/sdk/models/shared';

export enum SupportedItemTypes {
  GROUP_ASSET = 'GROUP_ASSET',
  GROUP_LIABILITY = 'GROUP_LIABILITY',
  UNKNOWN = 'UNKNOWN',
}

export enum SupportedStatus {
  ACTIVE = 'ACTIVE',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
}

export enum AssetItemTypes {
  CASH_AND_CASH_EQUIVALENTS = 'CASH_AND_CASH_EQUIVALENTS',
  LOAN_RECEIVABLE = 'LOAN_RECEIVABLE',
  HEDGE_FUNDS_ALTERNATIVES = 'HEDGE_FUNDS_ALTERNATIVES',
  PRIVATE_EQUITY_FUNDS = 'PRIVATE_EQUITY_FUNDS',
  PUBLIC_EQUITY = 'PUBLIC_EQUITY',
  OPERATING_COMPANY = 'OPERATING_COMPANY',
  REAL_ESTATE = 'REAL_ESTATE',
  ANGEL_INVENSTING = 'ANGEL_INVENSTING',
  VENTURE_CAPITAL = 'VENTURE_CAPITAL',
  MISC = 'MISC',
  CRYPTO = 'CRYPTO',
  OPTION_RSU = 'OPTION_RSU',
  INSURANCE = 'INSURANCE',
}

export enum LiabilityItemTypes {
  LINE_OF_CREDIT = 'LINE_OF_CREDIT',
  CREDIT_CARD = 'CREDIT_CARD',
  LOAN_PERSONAL = 'LOAN_PERSONAL',
  MORTGAGE = 'MORTGAGE',
  OTHER = 'OTHER',
}

/* --- STATE --- */
export type BaseContext = Record<string, any>;

export type ValueHistory = {
  date: string;
  value?: number;
  cost?: number;
  commitedCapital?: number;
  transactionType?: string;
} & BaseContext;

export interface AssetBaseContext extends BaseContext {
  type: string;
  name: string;
  value: number;
  ownership: string;
  restricted: boolean;
  leverageable: boolean;
  domesticForeign: string;
  percentage: number;
  lockedCapitalPercentage: number;
  capitalLockedUntil: string;
  leveragePercentage: number;
  restrictions: string;
  meta?: any | null;
  valueHistory?: ValueHistory[];
}

export interface LiabilityBaseContext extends BaseContext {
  type: string;
  name: string;
  value: number;
  ownership: string;
  valueHistory?: ValueHistory[];
  // dueDate: string;
  // interestRate: number;
  // paymentSchedule: string;
  // paymentAmount: number;
}

export enum UpdateFieldOptions {
  BUY = 'BUY',
  SELL = 'SELL',
  MARKET_VALUE = 'MARKET_VALUE',
}

export enum ValuationMethodOptions {
  CUSTOM_VALUE = 'CUSTOM_VALUE',
  MARKET_VALUE = 'MARKET_VALUE',
}

export type Position = {
  id: string;
  category: string;
  class: string;
  bookValue: number;
  quantity: number;
  marketValue: number;
  gainAmount: number;
  gainCurrencyAmount: number;
  gainPercent: number;
  currency: string;
  securityId: string;
  securityName: string;
  renderMarketValue?: number;
  renderBookValue?: number;
};

export type PositionWithDate = Position & {
  valuationDate: string;
};

export type PositionWithUpdate = PositionWithDate & {
  action: keyof typeof UpdateFieldOptions;
  valuationMethod?: keyof typeof ValuationMethodOptions;
};

export type Transaction = {
  date: string;
  code: string;
  description: string;
  debit: number;
  credit: string;
  balance: number;
  id: string;
  subtype?: string;
};

export type Item = {
  _id: string;
  integrationHash?: string;
  groupId: string;
  itemType: SupportedItemTypes;
  createdBy: string;
  createdOn: string;
  updatedOn?: string;
  baseContext: BaseContext & {
    positions?: Position[];
    transactions?: Transaction[];
  };
  linkedItemIdList: string[];
  links: { _id: string; linkType: string }[];
  ownership: string;
  status: SupportedStatus;
  stakeholders: Stakeholder[];
  companyId?: string;
};

export interface UpdateItem {
  _id?: string;
  groupId?: string;
  itemType?: SupportedItemTypes;
  createdBy?: string;
  createdOn?: string;
  baseContext?: BaseContext;
  ownership?: string;
}

export interface UpdateAsset extends UpdateItem {
  itemType?: SupportedItemTypes.GROUP_ASSET;
  valuationChoices?: ValuationChoices;
}

export interface UpdateLiability extends UpdateItem {
  itemType?: SupportedItemTypes.GROUP_LIABILITY;
  valuationChoices?: ValuationChoices;
}

export interface YearEndDate {
  month: number;
  day: number;
  year: number;
}
export interface ValuationChoices {
  industry: string;
  ebitda: number;
  currency: string;
  resultingMultiplier: number;
  yearEndDate?: YearEndDate;
}

export type userGroupId = string;
export interface viewAsset {
  itemId: string;
  groupId: string;
}

export interface Asset extends Item {
  itemType: SupportedItemTypes.GROUP_ASSET;
  baseContext: AssetBaseContext;
  valuationChoices?: ValuationChoices;
}

export interface Liability extends Item {
  itemType: SupportedItemTypes.GROUP_LIABILITY;
  baseContext: LiabilityBaseContext;
}
export type AssetInput = Omit<Asset, '_id'>;
export type LiabilityInput = Omit<Liability, '_id'>;
export type ItemInput = LiabilityInput | AssetInput;

export interface AssetByType {
  [key: string]: Asset[];
}

export interface LiabilityByType {
  [key: string]: Liability[];
}
export interface AssetLiabilityCalculationByType {
  available_leverage: string;
  total_net_worth: string;
  return_year_over_year: string;
  available_liquidity: string;
  available_cash: string;
}

export type AccountingDataReports = {
  accountingBalanceSheet?: AccountingBalanceSheet;
  accountingProfitAndLoss?: ProfitAndLossReport;
  accountingCashFlow?: CashFlowStatement;
};
export interface AssetLiabilityState {
  asset: Asset | null;
  assets?: Asset[];
  isFetchingAssets: boolean;
  getAssetsError: Error | null;
  isCreatingAsset: boolean;
  createAssetError: string | null;
  isEditingAsset: boolean;
  editAssetError: Error | null;
  editItemError?: Error;
  assetCreateStatus: 'success' | 'error' | null;
  assetEditStatus?: 'success' | 'error';
  isEditingItem: boolean;
  itemEditStatus?: 'success' | 'error';
  createdAsset: Asset | null;
  isCreatingLiability: boolean;
  createLiabilityError: string | null;
  isEditingLiability: boolean;
  isFetchingLiabilityView: boolean;
  isFetchingAssetView: boolean;
  editLiabilityError: Error | null;
  liabilityCreateStatus: 'success' | 'error' | null;
  liabilityEditStatus?: 'success' | 'error';
  createdLiability: Liability | null;
  liabilities?: Liability[];
  isFetchingLiabilities: boolean;
  getLiabilitiesError: Error | null;
  assetsLiabilityCalculation: AssetLiabilityCalculationByType | null;
  isFetchingAssetsLiabilityCalculation: boolean;
  getAssetsLiabilityError: Error | null;
  getAssetEditError: Error | null;
  isDeletingAsset: boolean;
  deleteAssetError: Error | null;
  getLiabilityEditError: Error | null;
  liability: Liability | null;
  deleteLiabilityError: Error | null;
  isDeletingLiability: boolean;
  isAssociatingItem: boolean;
  refreshDashboard: boolean;
  assestPercentageByClass: HoldingReadingInterface[] | null;
  deleteItemStatus?: 'success' | 'error';
  isFetchingAccountingData: boolean;
  getAccountingDataError: Error | null;
  accountingData: AccountingDataReports;
}
export interface NameIdType {
  name: string;
  id: string;
}

export interface HoldingReadingInterface {
  value: number;
  className: string;
}

export interface CurrentHoldingInterface {
  data: AssetByType;
  currencyState: CurrencyStateProps;
  groups: GroupData[];
}

export enum filterValues {
  BY_CLASS = 'BY_CLASS',
  BY_ALLOCATION = 'BY_ALLOCATION',
}

export type FilterConsolidationType = {
  filterClass: string[];
  setFilterClass: React.Dispatch<React.SetStateAction<string[]>>;
  filterSelected: string;
  setFilterSelected: React.Dispatch<React.SetStateAction<filterValues>>;
};
