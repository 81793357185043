import Multiselect from 'multiselect-react-dropdown';
import { InputLabel } from '@mui/material';
import { ReactFragment } from 'react';
import styled from 'styled-components/macro';
import DownArrow from '../../assets/icons/down-arrow-down.svg';
import { NameIdType } from 'app/features/AssetLiabilitySlice/types';
import style from './style';

interface Props {
  optionsData: NameIdType[];
  onSelect: (selectedList: any, selectedItem: any) => void;
  onRemove: (selectedList: any, selectedItem: any) => void;
  showCheckbox?: boolean;
  customCloseIcon?: ReactFragment;
  groupBy?: string;
  displayValue?: string;
  onSearch?: () => void;
  placeholder?: string;
  label?: string;
  selectedValues?: any;
}

export function MultiselectDropdown({
  optionsData,
  onSelect,
  showCheckbox,
  customCloseIcon,
  groupBy,
  displayValue,
  onSearch,
  onRemove,
  placeholder,
  label,
  selectedValues,
}: Props) {
  return (
    <MultiselectDropdownWrapper>
      {label && <InputLabel sx={style.inputLabelStyle}>{label}</InputLabel>}
      <Multiselect
        displayValue={displayValue}
        groupBy={groupBy}
        onRemove={onRemove}
        onSearch={onSearch}
        onSelect={onSelect}
        customCloseIcon={customCloseIcon}
        options={optionsData}
        placeholder={placeholder}
        showCheckbox={showCheckbox}
        selectedValues={selectedValues}
      />
      <img src={DownArrow} className="arrow-icon" alt="arrow" />
    </MultiselectDropdownWrapper>
  );
}

// TODO Remove code with styled from Components
const MultiselectDropdownWrapper = styled.div`
  position: relative;
  .searchWrapper {
    padding: 12px;
    border: 0;
    display: flex;
    overflow: auto;
  }
  .multiSelectContainer {
    padding-right: 22px;
    border-radius: 4px;
    border: 1px solid #ebeae8;
    margin-bottom: 135px;
  }
  .multiSelectContainer ul {
    max-height: 130px;
  }
  .arrow-icon {
    width: 12px;
    position: absolute;
    right: 10px;
    top: 46px;
  }
  li.groupChildEle {
    padding-left: 18px;
    font-size: 14px;
  }
  li.groupHeading {
    color: #40464d;
    font-size: 11px;
  }
  .multiSelectContainer li:hover {
    background: #fff;
    color: #000;
    cursor: pointer;
  }
  .chip {
    background: #2c3c3d;
    color: white;
    margin-bottom: 0px;
    padding: 2px 12px;
  }
  .multiSelectContainer input {
    font-size: 14px;
    margin-top: 0;
    vertical-align: middle;
  }
  .custom-close {
    display: flex;
    font-style: normal;
    font-size: 13px;
    margin-left: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    justify-content: center;
    line-height: 14px;
    color: #908c8c;
  }
  .highlightOption {
    background: none;
    color: #121212;
  }
`;
