import React from 'react';

import { Box, Autocomplete, BaseTextFieldProps } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import CL from 'country-telephone-data';
import { Controller, UseFormReturn, ValidationRule } from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import * as t from 'io-ts';
import { Input } from '../Input';
import { useTranslation } from 'react-i18next';
import { getInputErrors, getRequiredRuleValue } from '../utils';

export const CountryCodec = t.type({
  name: t.string,
  iso2: t.string,
  dialCode: t.string,
  priority: t.number,
  format: t.string,
});
export type Country = t.TypeOf<typeof CountryCodec>;

export const countries = (Array.from(new Set(CL.allCountries)) as Country[])
  .sort((a, b) => Number(a.priority) - Number(b.priority))
  .sort((a, b) => Number(a.dialCode) - Number(b.dialCode));

export interface CountrySelectProps extends BaseTextFieldProps {
  label?: string;
  name: string;
  size?: 'small' | 'medium';
  enabledCountriesIsos?: string[];
  disabled?: boolean;
  useFormInstance: UseFormReturn<any>;
  otherRules?: Record<string, ValidationRule>;
}

export const ControlledInputCountry = (props: CountrySelectProps) => {
  const {
    name,
    useFormInstance,
    label,
    disabled,
    enabledCountriesIsos,
    otherRules,
    ...inputProps
  } = props;
  const { control, getFieldState } = useFormInstance;
  const { t } = useTranslation();
  const isRequired = getRequiredRuleValue({ otherRules, disabled });
  const { hasError, errorMessage } = getInputErrors({
    name,
    disabled,
    getFieldState,
  });
  const helperMessage =
    errorMessage || (isRequired && capitalize(t('thisFieldIsRequired'))) || '';

  const enabledCountries = countries
    .filter(
      country =>
        !enabledCountriesIsos || enabledCountriesIsos.includes(country.iso2),
    )
    .map(({ name }) => name);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: !disabled,
          message: capitalize(t('thisFieldIsRequired')),
        },
        ...otherRules,
      }}
      render={({ field: { ref, ...field } }) => (
        <Autocomplete
          {...field}
          onChange={(e, data) => field.onChange(data)}
          id={name}
          options={enabledCountries}
          disabled={disabled}
          autoHighlight
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option}
            </Box>
          )}
          renderInput={params => (
            <Input
              {...params}
              {...inputProps}
              error={hasError}
              HelperTextIcon={InfoOutlined}
              helperText={helperMessage}
              label={capitalize(t('country'))}
              inputProps={params.inputProps}
            />
          )}
        />
      )}
    />
  );
};
