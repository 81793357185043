export const palette = {
  text: {
    primary: '#101820',
    secondary: '#10182099',
    disabled: '#10182061',
  },
  primary: {
    main: '#5DBD77',
    dark: '#48925C',
    medium: '#5B7363',
    light: '#8DCB9B',
    contrast: '#FFFFFF',
    back: '#2C3C3D',
    '4p': '#5DBD770A',
    '8p': '#5DBD7714',
    '12p': '#5DBD771F',
    '30p': '#5DBD774D',
    '50p': '#5DBD7780',
    '160p': '#F3F9F4',
  },
  secondary: {
    main: '#9C968E',
    dark: '#78746D',
    light: '#B4B0AA',
    contrast: '#FFFFFF',
    '4p': '#9C968E0A',
    '8p': '#9C968E14',
    '12p': '#9C968E1F',
    '30p': '#9C968E4D',
    '50p': '#9C968E80',
    '60p': '#10182099',
    '160p': '#D54340', // '#10222099'
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    contrast: '#FFFFFF',
    contrastText: '#303D45',
    '4p': '#D32F2F0A',
    '12p': '#D32F2F1F',
    '30p': '#D32F2F4D',
    '50p': '#D32F2F80',
    '160p': '#541313',
    '190p': '#FBEAEA',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrast: '#FFFFFF',
    '4p': '#ED6C020A',
    '12p': '#ED6C021F',
    '30p': '#ED6C024D',
    '50p': '#ED6C0280',
    '160p': '#5F2B01',
    '190p': '#FDF0E6',
  },
  info: {
    main: '#5DBD77',
    dark: '#48925C',
    light: '#8DCB9B',
    contrast: '#FFFFFF',
    back: '#5DBD7712',
    '4p': '#5DBD770A',
    '12p': '#5DBD771F',
    '30p': '#5DBD774D',
    '50p': '#5DBD7780',
    '160p': '#254C30',
    '190p': '#EFF8F1',
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    contrast: '#FFFFFF',
    '4p': '#2E7D320A',
    '12p': '#2E7D321F',
    '30p': '#2E7D324D',
    '50p': '#2E7D3280',
    '160p': '#123234',
    '190p': '#EAF2EA',
  },
  pending: {
    main: '#0277BD',
    dark: '#01579B',
    light: '#58A5F0',
    contrast: '#FFFFFF',
    '4p': '#0277BD0A',
    '12p': '#0277BD1F',
    '30p': '#0277BD4D',
    '50p': '#0277BD80',
    '160p': '#012A47',
    '190p': '#E3F2FB',
  },
  action: {
    active: '#0000008A',
    hover: '#0000000A',
    selected: '#00000014',
    disabled: '#00000042',
    disabledBackground: '#0000001F',
    focus: '#0000001F',
  },
  other: {
    divider: '#1018201F',
    outlineBorder: '#1018203B',
    inputLine: '#0000006B',
    backdropOverlay: '#00000080',
    ratingActive: '#FFB400',
    snackbarBackground: '#323232',
    sidebarInternal: '#F8FBFC',
    sidebar: '#EEEEEE',
  },
  addition: {
    infoBackgroundColor: '#E7F3EA',
    accordionBackgroundColor: '#F8F8F9',
    lightGreyLabelColor: '#D3D3D3',
    textDarkGrey: '#A9A9A9',
    controlButtonBackgroundColor: '#101820',
    entityNodePercentageColor: '#22372B',
    entityPersonalWrapperBackgroundColor: '#22372B',
    entityMapHandleBackgorund: '#555555',
    inputDateError: '#BA1A1A',
    assetHistoryEssential: '#E8EBEC',
    taskManagerDarkGreyColor: '#707479',
    tableListGreyColor: '#CFD1D2',
    iconWarningBackgroundColor: '#FAFAF9',
    taskManagerStatusToDoBackground: '#ECEFF1',
    taskManagerStatusDoingBackground: '#EDE7F6',
    taskManagerStatusDoingBorder: '#7E57C2',
    taskManagerStatusDoneBackground: '#E1F5FE',
    taskManagerStatusDoneBorder: '#00000042',
    vaultProcessingBg: '#e8e8e8',
    itemViewChartButtonBGColor: '#F2F2F2',
    itemViewChartButtonColor: '#67676',
  },
  static: {
    companyBackground: '#e1f5fe',
    companyIconColor: '#81d4fa',
    trustBackground: '#ece7f6',
    trustIconColor: '#b39ddb',
    foundationBackground: '#ffeac9',
    foundationIconColor: '#ffcc81',
    personalBackground: 'unset',
    personalIconColor: '#bdbdbd',
  },
};

// for all directories in palette
export type PaletteType = typeof palette;

// for primary, secondary, error, warning, info and success
export type PaletteDirectoryType = {
  main?: string;
  dark?: string;
  medium?: string;
  light?: string;
  contrast?: string;
  contrastText?: string;
  back?: string;
  '4p'?: string;
  '8p'?: string;
  '12p'?: string;
  '30p'?: string;
  '50p'?: string;
  '160p'?: string;
  '190p'?: string;
};
