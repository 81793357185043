import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Box } from '@mui/material';
import style from './style';
import { Input } from 'app/components/Inputs';
import { ControlledInputPhone } from 'app/components/Inputs/ControlledInputPhone';
import { UseFormReturn } from 'react-hook-form';
import { ControlledInputCountry } from 'app/components/Inputs/ControlledInputCountry';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import { Checkboxes, basicOptions } from 'app/components/Inputs/Checkboxes';
import { emailRules, lastNameRules, nameRules } from 'utils/validation.utils';

interface ClientInformationFormProps {
  //TODO : fix the type in all forms
  useFormInstance: UseFormReturn<any>;
  editUserInfo?: boolean | string;
  hideSignup?: boolean | string;
}

export function ClientInformationForm({
  useFormInstance,
  editUserInfo,
  hideSignup,
}: ClientInformationFormProps) {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors },
  } = useFormInstance;

  return (
    <Box sx={style.boxContainerStyles}>
      <ControlledInput
        formInstance={useFormInstance}
        name="firstName"
        label={capitalize(t('signUp.placeholders.firstName'))}
        disabled={Boolean(editUserInfo)}
        otherRules={nameRules}
      />
      <ControlledInput
        formInstance={useFormInstance}
        name="lastName"
        label={capitalize(t('signUp.placeholders.lastName'))}
        disabled={Boolean(editUserInfo)}
        otherRules={lastNameRules}
      />

      <ControlledInput
        formInstance={useFormInstance}
        name="email"
        label={capitalize(t('stakeholder.emailField'))}
        disabled={Boolean(hideSignup)}
        otherRules={emailRules}
      />

      <Input
        id="company"
        label={capitalize(t('advisors.companyField'))}
        error={Boolean(errors.company)}
        value={watch('company')}
        disabled={Boolean(editUserInfo)}
        {...register('company')}
      />
      <ControlledInputCountry
        useFormInstance={useFormInstance}
        name="country"
        disabled={Boolean(editUserInfo)}
      />
      <ControlledInputPhone
        useFormInstance={useFormInstance}
        size="small"
        disableInput={Boolean(editUserInfo)}
      />
      {!hideSignup && (
        <Checkboxes
          id="leverageable"
          label={capitalize(t('advisors.signupLabel'))}
          value={watch('signup')}
          options={basicOptions}
          disabled={Boolean(editUserInfo)}
        />
      )}
    </Box>
  );
}
