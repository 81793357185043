import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DeleteOutlineOutlined,
  GroupAdd,
  LinkOutlined,
  MoreVert,
  VisibilityOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Asset, Item, Liability } from 'app/features/AssetLiabilitySlice/types';
import { useAssetLiabilitySlice } from 'app/features/AssetLiabilitySlice';
import {
  selectDeletingAssetError,
  selectDeletingLiabilityError,
  selectIsdeletingAsset,
  selectIsdeletingLiability,
} from 'app/features/AssetLiabilitySlice/selectors';
import { selectGroups } from 'app/features/GroupSlice/selectors';
import { TableListCard } from 'app/components/TableListCard';
import { ColumnCustom } from 'app/components/TableListCard/ColumnCustom';
import { ColumnText } from 'app/components/TableListCard/ColumnText';
import { RemoveItemModal } from 'app/components/RemoveItemModal/Loadable';
import { getRoutePath } from 'app/routes/routePath';
import style from './style';
import { formatCurrencyToLocale } from 'app/components/CurrencyValue';

interface Props {
  data: Asset | Liability;
  hideview?: boolean;
  handleLiabilityRemove?: (e?: any, n?: string) => void | undefined;
  liability?: boolean;
  hideviewText?: string;
  onAddStakeholder?: (item: Item) => void;
  stakeRenderValue?: number;
}

export function AssetRow({
  data,
  hideview,
  handleLiabilityRemove,
  liability,
  onAddStakeholder,
  stakeRenderValue,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId: string }>();
  const [modal, setModal] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [isLiabilityLoading, setIsLiabilityLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { actions } = useAssetLiabilitySlice();
  const isDeletingAsset = useSelector(selectIsdeletingAsset);
  const isDeletingLiability = useSelector(selectIsdeletingLiability);
  const deletingAssetError = useSelector(selectDeletingAssetError);
  const deletingLiabilityError = useSelector(selectDeletingLiabilityError);
  const groups = useSelector(selectGroups);
  const [groupName, setGroupName] = useState<string>();
  const isOnlineAccount = Boolean(data && data.integrationHash);

  const onClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    localStorage.setItem('groupName', groupName ? groupName : '');
    const URL = getRoutePath({
      target: liability ? 'viewLiability' : 'viewAsset',
      pathParams: { itemId: data._id, groupId: groupId || 'consolidated' },
    });
    navigate(URL);
  };

  const onClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (data && data.integrationHash)
      navigate(
        getRoutePath({
          target: 'accounts',
          searchParams: {
            integrationId: data.integrationHash,
          },
        }),
      );
    else setModal(true);
  };

  const performDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (liability) {
      setIsLiabilityLoading(true);
      dispatch(
        actions.deleteLiability({
          itemId: data._id,
          groupId: data.groupId,
        }),
      );
    } else {
      setIsAssetLoading(true);
      dispatch(
        actions.deleteAsset({
          itemId: data._id,
          groupId: data.groupId,
        }),
      );
    }
    navigate(
      getRoutePath({
        target: 'consolidation',
        pathParams: { groupId: groupId || '' },
      }),
    );
    setModal(false);
  };

  useEffect(() => {
    if (groups && groups.length > 0) {
      for (const index in groups) {
        if (groups[index]._id === data.groupId) {
          setGroupName(groups[index].groupName);
          break;
        }
      }
    }
  }, [data.groupId, groups]);

  /*TODO: Refactor this, what is actually doing ? */
  useEffect(() => {
    if (deletingLiabilityError) {
      setIsLiabilityLoading(false);
    } else if (isLiabilityLoading && !isDeletingLiability) {
      setIsLiabilityLoading(false);
    }
  }, [
    actions,
    dispatch,
    isDeletingLiability,
    isLiabilityLoading,
    deletingLiabilityError,
    data.groupId,
  ]);
  useEffect(() => {
    if (deletingAssetError || (!isAssetLoading && !isDeletingAsset)) {
      setIsAssetLoading(false);
    }
  }, [
    actions,
    dispatch,
    isAssetLoading,
    isDeletingAsset,
    deletingAssetError,
    data.groupId,
  ]);

  const renderActionButtons = () =>
    hideview ? (
      <Button
        variant="text"
        sx={style.removeButtonStyles}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleLiabilityRemove &&
            handleLiabilityRemove(data._id, data.baseContext.name);
        }}
        disableRipple
      >
        <DeleteOutlineOutlined />
      </Button>
    ) : (
      <div style={style.iStyle}>
        {isOnlineAccount && (
          <Tooltip
            title={t('itemShortcuts.tooltips.onlineAccount')}
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            componentsProps={{
              tooltip: {
                sx: style.tooltipSx,
              },
            }}
          >
            <Typography sx={style.buttonSx}>
              <LinkOutlined />
            </Typography>
          </Tooltip>
        )}
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </div>
    );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <RemoveItemModal
        modal={modal}
        itemType={liability ? 'liability' : 'asset'}
        setModal={setModal}
        onDelete={performDelete}
        itemName={data.baseContext.name}
      />
      <TableListCard
        id={data._id}
        cardStyles={style.cardListStyles}
        onClick={onClickEdit}
      >
        <ColumnText
          title={data.baseContext?.customName ?? data.baseContext.name}
          subtitle={t('consolidation.labels.name')}
          gridSize={3}
          titleSx={style.titleStyles}
          subtitleSx={style.subtitleStyles}
        />
        <ColumnText
          title={groupName}
          subtitle={t('consolidation.labels.owner')}
          gridSize={3}
          titleSx={style.titleStyles}
          subtitleSx={style.subtitleStyles}
        />
        <ColumnText
          title={formatCurrencyToLocale(
            data.baseContext.renderCurrency,
            stakeRenderValue ?? data.baseContext.renderValue,
          )}
          subtitle={t('consolidation.labels.currentValue')}
          gridSize={3}
          titleSx={style.currentValueStyles}
          subtitleSx={style.subtitleStyles}
        />

        <ColumnCustom
          element={renderActionButtons()}
          gridSize={3}
          cellSx={style.buttonsStyles}
        />

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {onAddStakeholder && (
            <MenuItem
              sx={style.menuItemStyles}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                onAddStakeholder(data as Item);
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button sx={style.buttonSx} disableRipple>
                  <GroupAdd fontSize="small" />
                </Button>
                <Typography variant="body2">
                  {capitalize(t('consolidation.buttons.addStakeholder'))}
                </Typography>
              </Stack>
            </MenuItem>
          )}

          <MenuItem onClick={onClickEdit} sx={style.menuItemStyles}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button sx={style.buttonSx} onClick={onClickEdit} disableRipple>
                <VisibilityOutlined />
              </Button>
              <Typography variant="body2">
                {capitalize(t('assetEditPage.buttons.viewAsset'))}
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem onClick={onClickDelete} sx={style.menuItemStyles}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button sx={style.buttonSx} disableRipple>
                <DeleteOutlineOutlined />
              </Button>
              <Typography variant="body2">
                {capitalize(t('viewAssetPage.buttons.deleteAsset'))}
              </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </TableListCard>
    </>
  );
}
