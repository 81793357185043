import { muiTheme } from 'styles/muiTheme';

export default {
  inputLabelStyle: {
    mb: 1,
    fontFamily: 'RobotoRegular',
    fontSize: 14,
    color: muiTheme.palette.addition.textDarkGrey,
  },
  // TODO Started to convert css from styled: may use some of it
  // DropdownWrapperStyle: {
  //   position: 'relative',
  //   '> .searchWrapper': {
  //     padding: '12px',
  //     border: 0,
  //     display: 'flex',
  //     overflow: 'auto',
  //   },
  //   '> .multiSelectContainer': {
  //     pr: '22px',
  //     borderRadius: '4px',
  //     border: '1px solid #ebeae8',
  //     mb: '135px',
  //   },
  //   '> .multiSelectContainer ul': {
  //     maxHeight: '130px',
  //   },
  //   '> .arrow-icon': {
  //     width: '12px',
  //     position: 'absolute',
  //     right: '10px',
  //     top: '46px',
  //   },
  //   '> li.groupChildEle': {
  //     pl: '18px',
  //     fontSize: '14px',
  //   },
  //   '> li.groupHeading': {
  //     color: '#40464d',
  //     fontSize: '11px',
  //   },
  //   '> .multiSelectContainer li:hover': {
  //     backgroundColor: 'primary.contrast',
  //     color: '#000',
  //     cursor: 'pointer',
  //   },
  //   '> .chip': {
  //     backgroundColor: '#5dbd77',
  //     color: 'primary.contrast',
  //     mb: 0,
  //     padding: '2px 12px',
  //   },
  //   '> .multiSelectContainer input': {
  //     fontSize: '14px',
  //     mt: 0,
  //     verticalAlign: 'middle',
  //   },
  //   '> .custom-close': {
  //     display: 'flex',
  //     fontStyle: 'normal',
  //     fontSize: '13px',
  //     ml: '5px',
  //     cursor: 'pointer',
  //     border: '1px solid #ccc',
  //     borderRadius: '50%',
  //     width: '18px',
  //     height: '18px',
  //     textAlign: 'center',
  //     justifyContent: 'center',
  //     lineHeight: '14px',
  //     color: '#908c8c',
  //   },
  //   '> .highlightOption': {
  //     backgroundColor: 'none',
  //     color: '#121212',
  //   },
  // },
};
