import { Modal } from 'app/components/Modal.new';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import { useEffect } from 'react';
import { formatName } from '../../utils/files.utils';
import { useUpdateFileMutation } from '../../services';
import { FileConfig } from 'app/pages/vault/models/vault.types';
import * as styles from './style';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fileConfig: FileConfig;
};

export const ModalRename = ({ isOpen, onClose, fileConfig }: Props) => {
  const formInstance = useForm({
    mode: 'onBlur',
  });

  const {
    watch,
    setValue,
    formState: { isValid },
  } = formInstance;

  const { t } = useTranslation();

  const [updateFile] = useUpdateFileMutation();

  const fileName = watch('fileName');

  const { file, callback } = fileConfig;

  const handleRenameFile = () => {
    const data = {
      groupId: file?.groupId,
      payload: [
        {
          _id: file?._id,
          fileName: formatName(fileName),
        },
      ],
    };

    const newFile = {
      ...file,
      fileName: formatName(fileName),
    };

    updateFile(data).then(() => {
      callback(newFile);
      onClose();
    });
  };

  useEffect(() => {
    if (file?.fileName) {
      setValue('fileName', file.fileName);
    }
  }, [fileConfig, file?.fileName, setValue]);

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modalPreview}
      hideCloseButton
    >
      <Box width="100%">
        <Grid item xs={12} sx={{ padding: '0 20px' }}>
          <Typography variant="h5" mb={3} mt={3}>
            {t('vault.modals.renameFile.title')}
          </Typography>
          <Grid item xs={12}>
            <ControlledInput
              formInstance={formInstance}
              name="fileName"
              label="VaultFile"
              innerLabel={false}
            />
          </Grid>
        </Grid>
        <Card elevation={4} sx={{ padding: '20px' }}>
          <BottomRightGroupButtons
            onPrimaryBtnClick={onClose}
            primaryBtnText={t('vault.modals.renameFile.buttons.cancel')}
            secondaryBtnText={t('vault.modals.renameFile.buttons.save')}
            onSecondaryBtnClick={handleRenameFile}
            secondaryBtnDisabled={!isValid}
          />
        </Card>
      </Box>
    </Modal>
  );
};
