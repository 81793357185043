import { getFileNameAndExtension } from 'app/pages/vault/utils/files.utils';
import { putURL, useAddFileMutation } from 'app/pages/vault/services';
import moment from 'moment';
import { Folder, VaultFile } from 'app/pages/vault/models/vault.types';

export type UploadFileType = {
  name: string;
  size: number;
  type: string;
  result: string | ArrayBuffer | null;
  file: File;
};

// Function to create the file path
export const createFilePath = (folder: Folder) => {
  if (!folder) return '';
  return folder.id ? `/${folder.groupId}/${folder.id}` : `/${folder.groupId}`;
};

// Function to create payload for addFile API call
export const createPayload = (uploadedFile: UploadFileType, folder: Folder) => {
  const { fileName, extension } = getFileNameAndExtension(uploadedFile.name);
  const filePath = createFilePath(folder);

  return {
    fileName,
    filePath,
    extension,
    groupId: folder?.groupId,
  };
};

// Function to handle the file upload (API call)
export const uploadFile = async (
  uploadedFile: UploadFileType,
  folder: Folder,
  addFile: ReturnType<typeof useAddFileMutation>[0],
) => {
  const payload = createPayload(uploadedFile, folder);
  const data = {
    groupId: folder?.groupId,
    payload: [payload],
  };

  try {
    const result = await addFile(data);
    if ('data' in result && result.data?.payload?.length) {
      return result.data.payload[0]?.putUrl || null;
    }
  } catch (error) {
    return null;
  }
};

// Function to process the API response and create the VaultFile object
export const processFileResponse = (
  putUrl: string | null | undefined,
  uploadedFile: UploadFileType,
  folder: Folder,
  callback: (newFile: VaultFile) => void,
) => {
  if (!putUrl) return;

  const { fileName, extension } = getFileNameAndExtension(uploadedFile.name);
  const filePath = createFilePath(folder);

  // Perform the actual file upload to the URL
  putURL(putUrl, uploadedFile.file, extension);

  // Create new VaultFile object
  const newFile: VaultFile = {
    _id: `${filePath}/${fileName}.${extension}`,
    extension,
    fileName,
    filePath,
    lastModified: moment(uploadedFile.file.lastModified).toISOString(),
    size: uploadedFile.file.size,
    createdBy: folder?.id,
    groupId: folder?.groupId,
    contentLength: uploadedFile.file.size,
    contentType: uploadedFile.file.type,
    key: fileName,
    createdOn: new Date().toISOString(),
    previewUrl: URL.createObjectURL(uploadedFile.file),
    isProcessing: true,
  };

  // Invoke callback to pass the file back
  callback(newFile);
};
