import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  List,
  Box,
  Divider,
  ListItemButton,
  Typography,
} from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { Add, Inbox } from '@mui/icons-material';
import {
  selectGroupsByUserId,
  selectIsCreatingGroup,
  createGroupSuccess,
  selectCurrentGroupId,
} from 'app/features/GroupSlice/selectors';
import { useUser } from 'providers/UserProvider';
import { ConsolidatedGroupData, dataStatusTask } from 'constants/keyValue';
import { ListItemAccordion } from 'app/components/ListItemAccordion';
import { ListItemSideBar } from '../ListItemSideBar/Loadable';
import { ModalActivateEntities } from '../ModalActivateEntities/Loadable';
import { ListAdminGroups } from '../ListAdminGroups/Loadable';
import { useNavigate, useParams, useLocation } from 'react-router';
import { ManageEntityModal } from '../../ManageEntityModal/Loadable';
import { useGroupSlice } from 'app/features/GroupSlice';
import { useUserTasksSlice } from 'app/features/UserTasksSlice';
import { getBaseRouteTarget, getRoutePath } from 'app/routes/routePath';
import { SharedWithMeButton } from '../SharedWithMeButton/Loadable';
import SidebarStyles from './style';
import {
  selectContactImpersonatedList,
  selectIsImpersonating,
  selectgetImpersonatedClient,
} from 'app/features/AddressBookSlice/selectors';
import { ListClients } from '../ListClients/Loadable';
import { MembershipType } from 'providers/UserProvider/types';
import { useAddressBookSlice } from 'app/features/AddressBookSlice';
import { selectArchivedTask } from 'app/features/UserTasksSlice/selectors';
const {
  sharedAccordionTitleStyles,
  buttonLigthGrayStyles,
  sidebarStyles,
  internalSidebarSx,
  dividerStyles,
  outsideDividerStyles,
  expandendAccordionStyles,
  accordionStyles,
  expandendTitleAccordionStyles,
  addNewButtonStyles,
  entitiesDetailsStyles,
  goToEntityButtonStyles,
  containerStyle,
  addClientButtonStyles,
  noClienttitle,
  buttonStyle,
  outlinedButtonStyles,
  dividerSx,
} = SidebarStyles;

export function SideBarContent({
  addNewGroup,
  clickOpenUpgradeModal,
  onClickSelectGroup,
}: {
  clickOpenUpgradeModal: () => void;
  addNewGroup: (event: React.MouseEvent) => void;
  onClickSelectGroup: (event: React.MouseEvent) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const isImpersonating = useSelector(selectIsImpersonating);
  const groupsDataList = useSelector(selectGroupsByUserId);
  const selectArchived = useSelector(selectArchivedTask);
  const groupId = useSelector(selectCurrentGroupId);

  const dispatch = useDispatch();
  const { actions: groupActions } = useGroupSlice();
  const { actions: stakeholderActions } = useAddressBookSlice();
  const { actions: taskActions } = useUserTasksSlice();

  const isScenarioModeling = location.pathname.includes('scenario-modeling');
  const isTasksView = location.pathname.includes('tasks');

  const onClickSelectStatusTask = event => {
    const status = event.currentTarget.id;
    dispatch(taskActions.statusTask(status));
  };

  const onClickSelectArchivedTask = () => {
    dispatch(taskActions.typeTaskArchived(!selectArchived));
  };

  const onClickAddNewTask = () => {
    dispatch(taskActions.addNewTask(true));
  };
  return (
    <>
      {isTasksView ? (
        <>
          <ListItemSideBar
            icon={Add}
            title={t('taskManager.buttons.addNew')}
            button={true}
            sx={buttonStyle}
            onClick={onClickAddNewTask}
          />
          {dataStatusTask.map(data => (
            <ListItemSideBar
              title={data.groupName}
              button={true}
              sx={buttonLigthGrayStyles}
              onClick={onClickSelectStatusTask}
              groupId={data._id}
              isSelected={location.pathname.includes('allMyTasks')}
            />
          ))}
          <Divider variant="fullWidth" sx={dividerSx} />
          <ListItemSideBar
            icon={Inbox}
            title={
              !selectArchived
                ? t('taskManager.buttons.archived')
                : t('taskManager.buttons.viewUnarchived')
            }
            button={true}
            sx={outlinedButtonStyles}
            onClick={onClickSelectArchivedTask}
          />
        </>
      ) : null}

      {!isScenarioModeling && !isTasksView ? (
        <ListItemSideBar
          title={ConsolidatedGroupData.groupName}
          button={true}
          sx={buttonLigthGrayStyles}
          onClick={onClickSelectGroup}
          groupId={ConsolidatedGroupData._id}
          isSelected={ConsolidatedGroupData._id === groupId}
          variant="subtitle2"
        />
      ) : null}

      {!isTasksView ? (
        <ListAdminGroups
          groupsDataList={groupsDataList}
          onClickSelectGroup={onClickSelectGroup}
          isTypePersonal={true}
          selectedGroup={groupId}
          variant="subtitle2"
        />
      ) : null}
      {user?.account.membershipType !== MembershipType.ADVISOR &&
        isImpersonating && (
          <ListItemButton
            onClick={() => {
              dispatch(stakeholderActions.signOutImpersonateUser());
              navigate(
                getRoutePath({
                  target: 'consolidation',
                  pathParams: { groupId: '' },
                }),
              );
            }}
            sx={goToEntityButtonStyles}
          >
            <Typography variant="buttonMedium">
              {capitalize(t('sideBar.buttons.logOutImpersonate'))}
            </Typography>
          </ListItemButton>
        )}
      {!isTasksView ? (
        <ListItemAccordion
          title={ListItemSideBar({
            title: 'Entities',
            type: 'ENTITIES',
            variant: 'subtitle2',
          })}
          accordionSx={accordionStyles}
          contentListSx={expandendAccordionStyles}
          accordionDetailsSx={entitiesDetailsStyles}
          accordionSummarySx={expandendTitleAccordionStyles}
          name="Entities"
        >
          <ListAdminGroups
            groupsDataList={groupsDataList}
            onClickSelectGroup={onClickSelectGroup}
            selectedGroup={groupId}
            type="ENTITIES"
            variant="subtitle2"
          />
          {!isScenarioModeling ? (
            <>
              <ListItemButton
                onClick={event =>
                  groupsDataList.length > 1
                    ? addNewGroup(event)
                    : clickOpenUpgradeModal()
                }
                sx={addNewButtonStyles}
              >
                <Typography variant="buttonMedium">
                  {capitalize(t('addNew'))}
                </Typography>
              </ListItemButton>
              {groupsDataList.length > 1 && (
                <ListItemButton
                  onClick={() => navigate(getRoutePath({ target: 'entities' }))}
                  sx={goToEntityButtonStyles}
                >
                  <Typography variant="buttonMedium">
                    {capitalize(t('entity.buttons.goToItem'))}
                  </Typography>
                </ListItemButton>
              )}
            </>
          ) : null}
        </ListItemAccordion>
      ) : null}
    </>
  );
}

export function InternalSideBar({ isCollapsed }: { isCollapsed?: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();
  const { user } = useUser();
  const clientList = useSelector(selectContactImpersonatedList);
  const isImpersonating = useSelector(selectIsImpersonating);
  const isCreatingGroup = useSelector(selectIsCreatingGroup);
  const createGroupIsSuccess = useSelector(createGroupSuccess);
  const [addGroupModalIsOpen, setAddGroupModalIsOpen] = React.useState(false);
  const [ugradeGroupModalIsOpen, setUgradeGroupModalIsOpen] =
    React.useState(false);
  const dispatch = useDispatch();
  const getCurrentClient = useSelector(selectgetImpersonatedClient);
  const showSidebarClient = getCurrentClient._id.length > 1;
  const { actions: groupActions } = useGroupSlice();
  const { actions: stakeholderActions } = useAddressBookSlice();

  const addNewGroup = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAddGroupModalIsOpen(true);
  };

  const clickOpenUpgradeModal = () => {
    setUgradeGroupModalIsOpen(true);
  };

  const clickUpgradeModalButton = () => {
    setUgradeGroupModalIsOpen(false);
    setAddGroupModalIsOpen(true);
  };

  const onClickSelectGroup = event => {
    event.stopPropagation();
    const selectedGroupId = event.currentTarget.id;
    if (selectedGroupId && groupId !== selectedGroupId) {
      const basePathTarget = getBaseRouteTarget(location.pathname);
      const shouldRedirect = [
        'consolidation',
        'stakeholder',
        'scenario-modeling',
      ].includes(basePathTarget);
      navigate(
        getRoutePath({
          target: shouldRedirect ? basePathTarget : 'consolidation',
          pathParams: { groupId: selectedGroupId },
        }),
      );
    }
  };

  const isTasksView = location.pathname.includes('tasks');

  const isScenarioModeling = location.pathname.includes('scenario-modeling');
  const sidebarTitleString = isScenarioModeling
    ? 'scenarioModeling.headers.scenarioModeling'
    : isTasksView
      ? 'myTaskManager'
      : 'myFamilyOffice';

  const handleEntityModalSuccess = (entityData: {
    groupType: string;
    groupName: string;
    groupStakePercentage: number;
    groupAdmin?: string;
    groupId?: string;
    groupOwnership?: string;
  }) => {
    setAddGroupModalIsOpen(false);
    dispatch(groupActions.createGroup(entityData));
  };

  React.useEffect(() => {
    if (!createGroupIsSuccess) return;
    dispatch(groupActions.cleanupGroupOperation());
    dispatch(stakeholderActions.getContactSummaryList());
  }, [createGroupIsSuccess, dispatch, groupActions, stakeholderActions]);

  return (
    <Drawer
      variant="permanent"
      sx={internalSidebarSx}
      PaperProps={{ elevation: 12, sx: sidebarStyles }}
    >
      <ManageEntityModal
        mode="create"
        isOpenModal={addGroupModalIsOpen}
        setIsOpenModal={setAddGroupModalIsOpen}
        isCreatingGroup={isCreatingGroup}
        onComplete={handleEntityModalSuccess}
      />

      <ModalActivateEntities
        isOpenModal={ugradeGroupModalIsOpen}
        setIsOpenModal={setUgradeGroupModalIsOpen}
        onClickButton={clickUpgradeModalButton}
      />

      <Box>
        {user?.account.membershipType === MembershipType.ADVISOR && (
          <List sx={containerStyle}>
            <Divider variant="fullWidth" sx={dividerStyles} />
            <ListItemAccordion
              title={ListItemSideBar({
                title: capitalize(t('advisors.title')),
                titleOnClick: () =>
                  navigate(getRoutePath({ target: 'advisors' })),
              })}
              accordionSx={accordionStyles}
              contentListSx={expandendAccordionStyles}
              accordionDetailsSx={entitiesDetailsStyles}
              accordionSummarySx={expandendTitleAccordionStyles}
              defaultExpanded={!isCollapsed}
              name="clients"
            >
              <ListClients clientList={clientList} />
              {!showSidebarClient && clientList.length > 0 && (
                <ListItemButton
                  onClick={() => {
                    sessionStorage.setItem('userImpersonated', 'false');
                    sessionStorage.removeItem('supertoken');
                    navigate(
                      getRoutePath({
                        target: 'advisors',
                      }),
                      { state: { openModel: true } },
                    );
                  }}
                  sx={buttonLigthGrayStyles}
                >
                  <Typography variant="buttonMedium">
                    {capitalize(t('advisors.sidebar.addNewClient'))}
                  </Typography>
                </ListItemButton>
              )}
              {clientList.length === 0 && (
                <Typography variant="subtitle2" sx={noClienttitle}>
                  {capitalize(t('advisors.sidebar.noClients'))}
                </Typography>
              )}
              <ListItemButton
                onClick={() => {
                  sessionStorage.setItem('userImpersonated', 'false');
                  sessionStorage.removeItem('supertoken');
                  navigate(getRoutePath({ target: 'advisors' }));
                  dispatch(
                    stakeholderActions.getContactImpersonatedSummaryListCleanUp(),
                  );
                }}
                sx={
                  showSidebarClient ? addClientButtonStyles : addNewButtonStyles
                }
              >
                <Typography variant="buttonMedium">
                  {clientList.length > 0
                    ? capitalize(t('advisors.sidebar.title'))
                    : capitalize(t('advisors.sidebar.addNew.button'))}
                </Typography>
              </ListItemButton>
              <Divider variant="fullWidth" sx={{ ...dividerStyles, my: 3 }} />
              {showSidebarClient && (
                <SideBarContent
                  addNewGroup={addNewGroup}
                  clickOpenUpgradeModal={clickOpenUpgradeModal}
                  onClickSelectGroup={onClickSelectGroup}
                />
              )}
            </ListItemAccordion>
          </List>
        )}
        {user?.account.membershipType === MembershipType.ADVISOR ? (
          <Box>
            <SharedWithMeButton isImpersonating={isImpersonating} />
          </Box>
        ) : (
          <List>
            {/* MyFo Section */}
            <Typography variant="h6">
              {capitalize(t(sidebarTitleString))}
            </Typography>
            <Divider variant="fullWidth" sx={outsideDividerStyles} />
            <SideBarContent
              addNewGroup={addNewGroup}
              clickOpenUpgradeModal={clickOpenUpgradeModal}
              onClickSelectGroup={onClickSelectGroup}
            />

            <Divider variant="fullWidth" sx={outsideDividerStyles} />
            {!isScenarioModeling && !isTasksView ? (
              <ListItemAccordion
                disableExpandIcon={!isImpersonating}
                defaultExpanded={isImpersonating}
                accordionSx={accordionStyles}
                accordionSummarySx={sharedAccordionTitleStyles}
                title={<SharedWithMeButton isImpersonating={isImpersonating} />}
                name="sharedWithMeButton"
              >
                <SideBarContent
                  addNewGroup={addNewGroup}
                  clickOpenUpgradeModal={clickOpenUpgradeModal}
                  onClickSelectGroup={onClickSelectGroup}
                />
              </ListItemAccordion>
            ) : null}
          </List>
        )}
      </Box>
    </Drawer>
  );
}
