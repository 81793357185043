import { capitalize } from '@mui/material/utils';
import {
  RegisterOptions,
  UseFormGetFieldState,
  ValidationValueMessage,
} from 'react-hook-form';

export const getRequiredRuleValue = ({
  otherRules,
  disabled,
  defaultValue = true,
}: {
  otherRules?: RegisterOptions;
  disabled?: boolean;
  defaultValue?: boolean;
}): boolean => {
  if (disabled) return false;
  if (!otherRules || otherRules.required === undefined) return defaultValue;
  if (typeof otherRules.required === 'object') {
    return Boolean((otherRules.required as ValidationValueMessage).value);
  } else {
    return Boolean(otherRules.required);
  }
};

export const getInputErrors = ({
  name,
  disabled,
  getFieldState,
}: {
  name: string;
  getFieldState: UseFormGetFieldState<any>;
  disabled?: boolean;
}): { hasError: boolean; errorMessage?: string } => {
  const { error } = getFieldState(name);
  const hasError = !disabled && Boolean(error?.message);
  const errorMessage =
    hasError && error?.message ? capitalize(error.message) : undefined;
  return { errorMessage, hasError };
};
