import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select as MUISelect,
  BaseSelectProps as MUISelectProps,
  MenuItem,
  FormHelperText,
  Typography,
  ListSubheader,
  SxProps,
} from '@mui/material';
import { FormHelperIconSx, FormHelperTextSx } from '../Input';

export interface Option {
  label: string;
  value: string;
}
export interface OptionWithGroup {
  label: string;
  options: Option[];
}

export interface SelectProps extends MUISelectProps {
  label: string;
  options: string[] | number[] | Option[] | OptionWithGroup[];
  isError?: boolean;
  disableHelperText?: boolean;
  helperText?: React.ReactNode;
  HelperTextIcon?: React.ElementType;
  value?: string;
  containerSx?: SxProps;
  IconOptions?: React.ElementType;
}

export function Select(props: SelectProps) {
  const {
    isError = false,
    options,
    name,
    placeholder,
    helperText,
    disableHelperText,
    HelperTextIcon,
    size = 'medium',
    containerSx,
    IconOptions,
    variant = 'outlined',
  } = props;

  const errorColor = React.useMemo(
    () => (isError ? { color: 'error.main' } : {}),
    [isError],
  );

  const renderHelperText = () => (
    <>
      {helperText && HelperTextIcon && (
        <HelperTextIcon style={FormHelperIconSx} />
      )}
      {helperText}
    </>
  );

  const renderOptions = () => {
    if (
      typeof options[0] === 'object' &&
      options[0].hasOwnProperty('options')
    ) {
      return (options as OptionWithGroup[]).map(
        (group: OptionWithGroup, index) => [
          <ListSubheader key={`${group.label}-${index}`}>
            {group.label}
          </ListSubheader>,
          ...group.options.map((option: Option, index) => (
            <MenuItem
              divider
              value={option.value}
              key={`${group.label}-${option.label}-${index}`}
            >
              {option.label}
            </MenuItem>
          )),
        ],
      );
    } else {
      return (options as string[] | number[] | Option[]).map(
        (option: string | number | Option, index) => (
          <MenuItem
            divider
            sx={{ border: IconOptions && 'none' }}
            value={typeof option === 'object' ? option.value : option}
            key={`${name}-${index}`}
          >
            {IconOptions && <IconOptions sx={{ mr: '10px' }} />}
            {typeof option === 'object' ? option.label : option}
          </MenuItem>
        ),
      );
    }
  };

  return (
    <Box sx={{ minWidth: '11rem', ...containerSx }}>
      <FormControl fullWidth size={size}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            ...errorColor,
            backgroundColor: 'white',
          }}
        >
          {props.label}
        </InputLabel>
        <MUISelect
          fullWidth
          sx={{ ...props.sx }}
          value={props.value || ''}
          size={size}
          variant={variant}
          labelId="demo-simple-select-label"
          renderValue={selected => {
            const renderedPlaceholder = (
              <Typography variant="inputText" color="text.disabled">
                {placeholder || ''}
              </Typography>
            );
            if (!selected && placeholder) {
              return renderedPlaceholder;
            }
            if (!options.length) return renderedPlaceholder;
            if (
              typeof options[0] === 'object' &&
              options[0].hasOwnProperty('options')
            ) {
              const flattenedOptions = (options as OptionWithGroup[]).reduce(
                (accumulator, currentValue) =>
                  accumulator.concat(currentValue.options),
                [] as Option[],
              );
              const option = flattenedOptions.find(
                ({ value }) => value === selected,
              );
              return option?.label || renderedPlaceholder;
            }
            if (typeof options[0] === 'object') {
              const option = (options as Option[]).find(
                ({ value }) => value === selected,
              );
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {IconOptions && (
                    <IconOptions sx={{ mr: '10px', width: '19px' }} />
                  )}
                  {option?.label || renderedPlaceholder}
                </Box>
              );
            }
            return selected;
          }}
          native={false}
          displayEmpty
          error={isError}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {renderOptions()}
        </MUISelect>
        {!disableHelperText && (
          <FormHelperText
            margin="dense"
            sx={{
              ...errorColor,
              FormHelperTextSx,
            }}
          >
            {renderHelperText()}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
