import React from 'react';
import { Box, Typography, Button } from '@mui/material';

import {
  LabelComponentBaseSx,
  LabelComponentBasicSx,
  LabelComponentVariant1,
  LabelComponentVariant2,
  LabelComponentVariant3,
  LabelComponentVariantBorder,
  LabelComponentVariantBorder2,
  LabelComponentVariantBorder3,
  LabelComponentVariantIconWarning,
  LabelComponentVariantInline,
  LabelComponentVariantInline2,
  LabelComponentVariantExtra,
} from './style';

interface Props {
  title: string | React.ReactNode;
  text?: string | React.ReactNode;
  helper?: React.ReactNode;
  variant?:
    | 'base'
    | 'variant1'
    | 'variant2'
    | 'variant3'
    | 'border'
    | 'border2'
    | 'border3'
    | 'variantIcon'
    | 'inline'
    | 'inline2'
    | 'extra';
}

const getSxVariant = variant => {
  switch (variant) {
    case 'base':
      return LabelComponentBaseSx;
    case 'basic':
      return LabelComponentBasicSx;
    case 'variant1':
      return LabelComponentVariant1;
    case 'variant2':
      return LabelComponentVariant2;
    case 'variant3':
      return LabelComponentVariant3;
    case 'border':
      return LabelComponentVariantBorder;
    case 'border2':
      return LabelComponentVariantBorder2;
    case 'border3':
      return LabelComponentVariantBorder3;
    case 'inline':
      return LabelComponentVariantInline;
    case 'inline2':
      return LabelComponentVariantInline2;
    case 'variantIcon':
      return LabelComponentVariantIconWarning;
    case 'extra':
      return LabelComponentVariantExtra;
    default:
      return LabelComponentBasicSx;
  }
};

export function LabelText({ title, text, variant, helper }: Props) {
  const { labelBoxSx, textFontSx, titleFontSx } = getSxVariant(variant);

  return text ? (
    <Box sx={labelBoxSx}>
      <Typography sx={titleFontSx}>{title}</Typography>
      <Typography sx={textFontSx}>{text}</Typography>
      {helper ? helper : null}
    </Box>
  ) : (
    <></>
  );
}
