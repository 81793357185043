import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { InputLabel, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import { Input, InputProps } from '../Input';
import { muiTheme } from 'styles/muiTheme';
import { getInputErrors } from '../utils';

export interface ControlledInputProps extends InputProps {
  name: string;
  label: string;
  innerLabel?: boolean;
  formInstance: UseFormReturn<any>;
  otherRules?: RegisterOptions;
}

export const ControlledInput = (props: ControlledInputProps) => {
  const { t } = useTranslation();
  const {
    name,
    label,
    disabled,
    innerLabel = true,
    otherRules,
    formInstance,
    placeholder,
    ...inputValueProps
  } = props;
  const { register, watch, getFieldState } = formInstance;
  const value = watch(name, inputValueProps.InputProps?.value || '');

  const { hasError, errorMessage } = getInputErrors({
    name,
    disabled,
    getFieldState,
  });

  const validationRules = {
    required: {
      value: !disabled,
      message: capitalize(t('thisFieldIsRequired')),
    },
    ...(disabled ? {} : otherRules || {}),
  };

  return (
    <>
      {!innerLabel && (
        <InputLabel htmlFor={name} sx={muiTheme.variants.inputLabel}>
          <Typography variant="body1" color="text.primary">
            {label}
          </Typography>
        </InputLabel>
      )}
      <Input
        {...inputValueProps}
        value={value}
        id={name}
        label={innerLabel ? label : undefined}
        placeholder={placeholder || label}
        isError={hasError}
        error={hasError}
        helperText={errorMessage}
        HelperTextIcon={InfoOutlined}
        disabled={disabled}
        {...register(name, validationRules)}
      />
    </>
  );
};
