import moment from 'moment';
import {
  groupItemsByType,
  formatCurrencyLabel,
  formatNumber,
  calcPercent,
  getPositionsValue,
  getPositionsValueWithCash,
} from 'utils/assetValues';
import { AssetConst } from 'constants/keyValue';
import { cashClasses, liquidityClasses } from 'constants/asset-liability';
import {
  currencyConversion,
  formatCurrencyToLocale,
} from 'app/components/CurrencyValue';
import { CurrencyStateProps } from 'providers/CurrencyProvider/types';
import {
  Item,
  SupportedItemTypes,
  BaseContext,
} from 'app/features/AssetLiabilitySlice/types';
import { GroupData } from 'app/features/GroupSlice/types';

type GroupCalculations = {
  available_cash: string;
  available_assets: string;
  available_liability: string;
  available_leverage: string;
  available_liquidity: string;
  annualReturn: number;
  totalCommitment: string;
  remainingCommitment: string;
  quarterlyReturn: number;
  tax_payable: string;
  total_net_worth: string;
};

type ExtractCalcFromItemsProps = {
  currencyState: CurrencyStateProps;
  filteredGroups: GroupData[];
  soldAssets?: any;
  remainingAmount?: number;
  paymentMethod?: string;
};

/**
 * The `formatSellData` function takes a `baseContext` object as input and returns a formatted sell
 * data object with various properties and values.
 * @param {BaseContext}  - - `baseContext`: An object containing the following properties:
 * @returns an object with the following properties:
 */
export const formatSellData = ({
  groupId,
  _id,
  createdBy,
  baseContext,
}: BaseContext) => {
  const { name, currency, totalValue, taxRate, cost = 0 } = baseContext;
  const gainAmount = totalValue - cost;
  const formattedTaxRate = taxRate / 100;
  const taxPayable = gainAmount * formattedTaxRate;
  return {
    name: name,
    meta: {
      assetId: _id,
      groupId,
      createdBy,
      type: baseContext?.type,
      subType: baseContext?.subType,
      percentageOwned: baseContext?.percentageOwned,
      cost: baseContext?.cost,
      totalValue: baseContext?.totalValue,
      taxRate: baseContext?.taxRate,
    },
    rows: [
      {
        label: 'scenarioModeling.simulator.sellData.salePrice',
        value: formatCurrencyLabel(totalValue, currency),
        color: 'primary.main',
      },
      {
        label: 'scenarioModeling.simulator.sellData.cost',
        value: formatCurrencyLabel(cost, currency),
        color: 'text.secondary',
      },
      {
        label: 'scenarioModeling.simulator.sellData.gain',
        value: formatCurrencyLabel(gainAmount, currency),
        color: 'text.primary',
      },
      {
        label: 'scenarioModeling.simulator.sellData.taxRate',
        value: formatNumber(formattedTaxRate, 'percent', 2),
        color: 'text.secondary',
      },
      {
        label: 'scenarioModeling.simulator.sellData.taxPayable',
        value: formatCurrencyLabel(taxPayable, currency),
        color: 'text.secondary',
      },
    ],
  };
};

/**
 * The function `getPaymentMethod` takes a payment method as input and returns a corresponding
 * description.
 * @returns The function `getPaymentMethod` returns a string representing the payment method based on
 * the input parameter `paymentMethod`.
 */
const getPaymentMethod = paymentMethod => {
  switch (paymentMethod) {
    case 'CASH':
      return 'Cash';
    case 'LOAN':
      return 'Loan';
    case 'SELL_ASSETS':
      return 'Selling Assets';
  }
};

/**
 * The `formatBuyData` function formats buy data by extracting specific properties from the
 * `baseContext` object and returning them in a formatted structure.
 * @param {BaseContext}  - - `baseContext`: An object containing the following properties:
 * @returns an object with the following properties:
 * - name: the value of the name property from the baseContext object
 * - rows: an array of objects, each representing a row of data. Each object has the following
 * properties:
 *   - label: a string representing the label for the data
 *   - value: the formatted value of the data, obtained by calling the formatCurrencyLabel or
 */
export const formatBuyData = ({ baseContext }: BaseContext) => {
  const { name, currency, purchasePrice, capitalCommitment, percentageOwned } =
    baseContext;
  const price = purchasePrice || capitalCommitment;
  const paymentMethod = getPaymentMethod(baseContext?.paymentMethod);
  return {
    name: name,
    rows: [
      {
        label: 'scenarioModeling.simulator.buyData.purchasePrice',
        value: formatCurrencyLabel(price, currency),
        color: 'text.primary',
      },
      {
        label: 'scenarioModeling.simulator.buyData.currency',
        value: currency,
        color: 'text.secondary',
      },
      {
        label: 'scenarioModeling.simulator.buyData.percentageOwnership',
        value: formatNumber(percentageOwned / 100, 'percent', 2),
        color: 'primary.main',
      },
      {
        label: 'scenarioModeling.simulator.buyData.paymentMethod',
        value: paymentMethod,
        color: 'text.secondary',
      },
      /*       {
        label: 'scenarioModeling.simulator.buyData.availableLeverage',
        value: formatNumber(percentageOwned, 'percent', 2),
        color: 'primary.main',
      }, */
    ],
  };
};

/**
 * The function filters an assets list by removing assets with a specific asset ID.
 * @param assetsList - The `assetsList` parameter is an object that contains a list of assets. Each
 * asset is represented by a key-value pair, where the key is the asset's identifier and the value is
 * an array of asset objects.
 * @param filterAsset - The `filterAsset` parameter is an object that contains information about the
 * asset to be filtered. It has a property `meta` which contains the `assetId` that will be used to
 * filter the `assetsList`.
 * @returns a new object that is a filtered version of the original assetsList. The filtered version
 * excludes any assets that have an _id property matching the assetId of the filterAsset object.
 */
export const filterAssetsList = (assetsList, filterAsset) => {
  return Object.entries(assetsList).reduce((acc, [key, value]: [any, any]) => {
    acc[key] = value.filter(asset => asset._id !== filterAsset._id);
    return acc;
  }, {});
};

/**
 * The function filters a group data array by excluding items with a specific asset ID.
 * @param groupData - An object containing data for a group. It should have a property called "items"
 * which is an array of items belonging to the group. Each item should have a property called "_id"
 * which represents its unique identifier.
 * @param assetId - The `assetId` parameter is the ID of an asset that you want to filter out from the
 * `groupData` array.
 * @returns a filtered array of items from the `groupData` object, excluding the item with the
 * specified `assetId`.
 */

export const filterGroupDataByAssetId = (groupData, assetId) => {
  return {
    ...groupData,
    items: groupData?.items.filter(item => item._id !== assetId),
  };
};

export const formatItemAPI = item => {
  const formattedObject = {
    _id: `${item.groupId}-${item?.baseContext?.name}-${Math.random()}`,
    baseContext: {
      type: item?.baseContext?.type,
      ownership: item.groupId,
      subType: item?.baseContext?.subType,
      name: item?.baseContext?.name,
      purchaseDate: moment().format('DD/MM/YYYY'),
      purchasePrice: item?.baseContext?.purchasePrice,
      currency: item?.baseContext?.currency,
      percentageOwned: '100',
      description: '',
      value: item?.baseContext?.value,
      totalValue: item?.baseContext?.totalValue,
      cost: item?.baseContext?.cost,
      date: moment().format('DD/MM/YYYY'),
      valuationDate: moment().format('DD/MM/YYYY'),
      valueHistory: [
        {
          type: item?.baseContext?.type,
          ownership: item.groupId,
          subType: item?.baseContext?.subType,
          name: item?.baseContext?.name,
          purchaseDate: moment().format('DD/MM/YYYY'),
          purchasePrice: item?.baseContext?.purchasePrice,
          currency: item?.baseContext?.currency,
          percentageOwned: '100',
          description: '',
          value: item?.baseContext?.value,
          totalValue: item?.baseContext?.totalValue,
          cost: item?.baseContext?.cost,
          date: moment().format('DD/MM/YYYY'),
          valuationDate: moment().format('DD/MM/YYYY'),
        },
      ],
      renderCurrency: item?.baseContext?.currency,
      renderPurchasePrice: item?.baseContext?.purchasePrice,
      renderRecentValuation: null,
      renderCost: item?.baseContext?.cost,
      renderSharePrice: null,
      renderRecentSharePrice: null,
      renderTotalCommitment: null,
      renderCapitalCommitment: null,
      renderRemainingCommitment: null,
      renderEbitda: null,
      renderEstimatedValue: null,
      renderValue: item?.baseContext?.value,
      renderTotalValue: item?.baseContext?.totalValue,
    },
    createdBy: 'user-id',
    createdOn: '2023-12-30T18:41:53.547Z',
    itemType: 'GROUP_ASSET',
    links: [
      {
        _id: item.groupId,
        linkType: 'GROUP',
      },
    ],
    status: 'ACTIVE',
    groupId: item.groupId,
  };

  return formattedObject;
};

export const createLiabilityAPI = item => {
  const formattedObject = {
    _id: `${item.groupId}-${item?.baseContext?.name}-LOAN-${Math.random()}`,
    baseContext: {
      type: 'LOAN_PERSONAL',
      ownership: item.groupId,
      name: `Loan for ${item?.baseContext?.name}`,
      purchaseDate: moment().format('DD/MM/YYYY'),
      value: item?.baseContext?.value,
      currency: item?.baseContext?.currency,
      maturityDate: moment().format('DD/MM/YYYY'),
      dueDate: moment().format('DD/MM/YYYY'),
      interestRate: '0',
      percentageOwned: '100',
      description: '',
      subType: 'CONVERTIBLE',
      valueHistory: [
        {
          type: 'LOAN_PERSONAL',
          ownership: item.groupId,
          name: `Loan for ${item?.baseContext?.name}`,
          purchaseDate: moment().format('DD/MM/YYYY'),
          value: item?.baseContext?.value,
          currency: item?.baseContext?.currency,
          maturityDate: moment().format('DD/MM/YYYY'),
          dueDate: moment().format('DD/MM/YYYY'),
          interestRate: '0',
          percentageOwned: '100',
          description: '',
          subType: 'CONVERTIBLE',
          date: moment().format('DD/MM/YYYY'),
        },
      ],
      renderCurrency: item?.baseContext?.currency,
      renderPurchasePrice: null,
      renderRecentValuation: null,
      renderCost: null,
      renderSharePrice: null,
      renderRecentSharePrice: null,
      renderTotalCommitment: null,
      renderCapitalCommitment: null,
      renderRemainingCommitment: null,
      renderEbitda: null,
      renderEstimatedValue: null,
      renderValue: item?.baseContext?.value,
      renderTotalValue: null,
    },
    createdBy: 'user-id',
    createdOn: '2023-12-30T18:41:53.547Z',
    itemType: 'GROUP_LIABILITY',
    links: [
      {
        _id: item.groupId,
        linkType: 'GROUP',
      },
    ],
    status: 'ACTIVE',
    groupId: item.groupId,
  };
  return formattedObject;
};

/* ============================ TODO: Refactor this ============================ */

/**
 * The function `getGroupItemValue` calculates the value of a group item based on a group percentage
 * and whether the group is consolidated.
 * @param {string | number} groupPercentage - The `groupPercentage` parameter is a string or number
 * representing the percentage value of a group.
 * @param {boolean} isConsolidated - A boolean value indicating whether the group is consolidated or
 * not.
 */
const getGroupItemValue =
  (groupPercentage: string | number, isConsolidated: boolean) =>
  (value: string | number) =>
    isConsolidated
      ? calcPercent(Number(value) || 0, Number(groupPercentage))
      : Number(value) || 0;

/**
 * The function `getReturnRecordIndex` returns the indices of the quarterly and annual records in a
 * given array of record history.
 * @param {Record<string, unknown>[]} recordHistory - recordHistory is an array of objects representing
 * a record history. Each object in the array has a "date" property which represents the date of the
 * record.
 * @returns The function `getReturnRecordIndex` returns an object with two properties: `quaterlyRecord`
 * and `annualRecord`.
 */
const getReturnRecordIndex = (
  recordHistory: Record<string, unknown>[],
): { quaterlyRecord: number; annualRecord: number } => {
  const historyLenght = recordHistory.length;
  if (!historyLenght) return { quaterlyRecord: -1, annualRecord: -1 };
  let quaterlyRecord: number | undefined;
  let annualRecord: number | undefined;
  const annualDate = moment().startOf('day').subtract(1, 'year');
  const quaterlyDate = moment().startOf('day').subtract(90, 'days');
  const lastRecordDate = moment(
    recordHistory[historyLenght - 1].date as string,
    'MM/DD/YYYY',
  ).startOf('day');
  if (annualRecord === undefined && lastRecordDate.isBefore(annualDate)) {
    annualRecord = historyLenght - 1;
  }
  if (quaterlyRecord === undefined && lastRecordDate.isBefore(quaterlyDate)) {
    quaterlyRecord = historyLenght - 1;
  }
  recordHistory.some((record, index) => {
    const recordDate = moment(record.date as string, 'MM/DD/YYYY').startOf(
      'day',
    );
    if (annualRecord === undefined && recordDate.isAfter(annualDate)) {
      annualRecord = index - 1;
    }
    if (quaterlyRecord === undefined && recordDate.isAfter(quaterlyDate)) {
      quaterlyRecord = index - 1;
    }
    if (annualRecord !== undefined && quaterlyRecord !== undefined) {
      return true;
    }
    return false;
  });
  if (annualRecord === undefined) {
    annualRecord = -1;
  }
  if (quaterlyRecord === undefined) {
    quaterlyRecord = -1;
  }
  return { quaterlyRecord, annualRecord };
};

/*
1.- Make soldASsets an array and sum up the totals.
*/
export const extractCalcFromItems = ({
  currencyState,
  filteredGroups,
  soldAssets,
  remainingAmount,
  paymentMethod,
}: ExtractCalcFromItemsProps): GroupCalculations => {
  let assetValue = 0;
  let assetValueLastYear = 0;
  let assetValueQuarterly = 0;
  let liabilityValue = 0;
  let liabilityValueLastYear = 0;
  let liabilityValueQuarterly = 0;
  let leverageValue = 0;
  let availableLiquidity = 0;
  let annualReturn = 0;
  let quarterlyReturn = 0;
  let availableCash = 0;
  let totalCommitment = 0;
  let remainingCommitment = 0;
  let taxPayable = 0;

  filteredGroups.forEach((group: GroupData) => {
    const groupPercentage = Number(group.groupStakePercentage);
    const getGroupItemPercentValue = getGroupItemValue(
      groupPercentage,
      filteredGroups.length > 1,
    );
    group?.items.forEach((item: Item) => {
      if (item.status === 'DELETED') return;

      const itemContext = item.baseContext;

      const convertedAssetValue = getGroupItemPercentValue(
        itemContext?.renderValue,
      );
      const convertedTotalCommitment = getGroupItemPercentValue(
        itemContext?.renderCapitalCommitment,
      );
      const convertedRemainingCommitment = getGroupItemPercentValue(
        itemContext?.renderRemainingCommitment,
      );
      const itemValueHistory = (itemContext?.valueHistory || []) as Record<
        string,
        unknown
      >[];
      const { quaterlyRecord, annualRecord } =
        getReturnRecordIndex(itemValueHistory);
      const convertedQAssetValue =
        quaterlyRecord !== -1
          ? getGroupItemPercentValue(
              currencyConversion(
                currencyState,
                itemContext?.currency,
                itemValueHistory[quaterlyRecord].value as string,
              ),
            )
          : convertedAssetValue;
      const convertedAAssetValue =
        annualRecord !== -1
          ? getGroupItemPercentValue(
              currencyConversion(
                currencyState,
                itemContext?.currency,
                itemValueHistory[annualRecord].value as string,
              ),
            )
          : convertedAssetValue;
      if (
        item.itemType === SupportedItemTypes.GROUP_ASSET &&
        itemContext.type !== AssetConst.PUBLIC_EQUITY
      ) {
        assetValue += convertedAssetValue;
        assetValueLastYear += convertedAAssetValue;
        assetValueQuarterly += convertedQAssetValue;

        if (itemContext.isLeverageable) {
          leverageValue += calcPercent(
            convertedAssetValue,
            Number(itemContext.leveragePercentage),
            false,
          );
        }
        if (itemContext.type && cashClasses.includes(itemContext.type)) {
          availableCash += convertedAssetValue;
        }
        if (
          itemContext.type &&
          liquidityClasses.includes(itemContext.type) &&
          item.createdBy === 'system'
        ) {
          availableLiquidity +=
            /* The above code is declaring a variable named "convertedAssetValue"
          in TypeScript. */
            /* The above code is declaring a variable named "convertedAssetValue"
          in TypeScript. */
            convertedAssetValue;
        }
        if (itemContext.totalCommitment) {
          totalCommitment += convertedTotalCommitment;
        }
        if (itemContext.remainingCommitment) {
          remainingCommitment += convertedRemainingCommitment;
        }
      }
      if (
        itemContext.type === AssetConst.PUBLIC_EQUITY &&
        itemContext?.positions
      ) {
        const totalValue = getPositionsValue(itemContext?.positions);

        assetValue += totalValue;
        availableLiquidity += totalValue;

        const totalCashValue = getPositionsValueWithCash(
          itemContext?.positions,
        );

        availableCash += totalCashValue;
      }

      if (item.itemType === SupportedItemTypes.GROUP_LIABILITY) {
        liabilityValue += convertedAssetValue;
        liabilityValueLastYear += convertedAAssetValue;
        liabilityValueQuarterly += convertedQAssetValue;
      }
    });
  });

  //Add available cash when sell scenario exists:
  if (soldAssets && soldAssets.length > 0) {
    const totalSoldAssets =
      soldAssets?.reduce((sum, asset) => {
        const value = asset.meta?.totalValue;
        return sum + (Number.isNaN(value) ? 0 : value);
      }, 0) || 0;

    const totalTaxPayable =
      soldAssets?.reduce((sum, asset) => {
        const gainAmount = asset.meta?.totalValue - asset.meta?.cost;
        const formattedTaxRate = asset.meta?.taxRate / 100;
        const value =
          gainAmount * (Number.isNaN(formattedTaxRate) ? 0 : formattedTaxRate);
        return sum + (Number.isNaN(value) ? 0 : value);
      }, 0) || 0;

    availableCash += totalSoldAssets;
    taxPayable += totalTaxPayable;
  }

  if (remainingAmount) {
    availableCash += Math.abs(remainingAmount);
  }

  /*FIXME: Disable this */
  const totalNetWorthLastYear = assetValueLastYear - liabilityValueLastYear;
  /*FIXME: Disable this */
  const totalNetWorthQuarterlyYear =
    assetValueQuarterly - liabilityValueQuarterly;
  /*FIXME: Disable this */
  const totalNetWorthCurrentYear =
    assetValue + availableCash - (liabilityValue + taxPayable);
  annualReturn =
    totalNetWorthLastYear > 0
      ? (totalNetWorthCurrentYear - totalNetWorthLastYear) /
        totalNetWorthLastYear
      : 0;
  quarterlyReturn =
    totalNetWorthQuarterlyYear > 0
      ? (totalNetWorthCurrentYear - totalNetWorthQuarterlyYear) /
        totalNetWorthQuarterlyYear
      : 0;

  return {
    total_net_worth: formatCurrencyToLocale(
      currencyState.base,
      totalNetWorthCurrentYear,
    ),

    available_assets: formatCurrencyToLocale(currencyState.base, assetValue),

    available_liability: formatCurrencyToLocale(
      currencyState.base,
      liabilityValue,
    ),

    available_liquidity: formatCurrencyToLocale(
      currencyState.base,
      availableLiquidity,
    ),
    available_leverage: formatCurrencyToLocale(
      currencyState.base,
      leverageValue,
    ),
    totalCommitment: formatCurrencyToLocale(
      currencyState.base,
      totalCommitment,
    ),
    remainingCommitment: formatCurrencyToLocale(
      currencyState.base,
      remainingCommitment,
    ),
    annualReturn: annualReturn,
    quarterlyReturn: quarterlyReturn,
    tax_payable: formatCurrencyToLocale(currencyState.base, taxPayable),
    available_cash: formatCurrencyToLocale(currencyState.base, availableCash),
  };
};
